<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row type="flex" :gutter="[8, 8]">
				<a-col>
					<a-select
						show-search
						:placeholder="l('流程表单')"
						option-filter-prop="children"
						style="width: 200px"
						:dropdownMatchSelectWidth="false"
						v-model="wfId"
						@change="wfRefresh"
					>
						<a-select-option v-for="item in wfScheme" :key="item.id">
							{{ item.name }}
							<a-tag color="blue" v-if="item.formMes == '开发表单'">{{ item.formMes }} </a-tag>
						</a-select-option>
					</a-select>
				</a-col>
				<a-col>
					<a-select
						show-search
						allowClear
						:placeholder="l('是否完成')"
						option-filter-prop="children"
						style="width: 200px"
						:dropdownMatchSelectWidth="false"
						v-model="isFinished"
						@change="refresh"
					>
						<a-select-option value="0">
							未完成
						</a-select-option>
						<a-select-option value="1">
							已完成
						</a-select-option>
						<a-select-option value="2">
							作废
						</a-select-option>
					</a-select>
				</a-col>
				<a-col>
					<a-range-picker
						style="width: 240px"
						:allowClear="false"
						@change="onDateChange"
						v-model="DateTime"
					/>
				</a-col>
			</a-row>
			<a-row type="flex" :gutter="[8, 8]">
				<a-col>
					<a-select
						show-search
						:allowClear="true"
						:placeholder="l('搜索字段')"
						option-filter-prop="children"
						style="width:200px"
						:dropdownMatchSelectWidth="false"
						v-model="seachField"
					>
						<a-select-option v-for="item in seachData" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col>
					<a-input-search
						name="seachFieldText"
						:placeholder="l('字段搜索关键字')"
						@search="search"
						enterButton
						v-model="seachFieldText"
						style="width: 200px"
					/>
				</a-col>
				<a-col>
					<a-button :type="'primary'" @click="search()">
						<a-icon type="search" />
						查询
					</a-button>
				</a-col>
				<a-col>
					<a-button :type="'primary'" @click="exportToExcel()" v-if="isGranted('hit_time_excel')">
						<a-icon type="download" />
						导出
					</a-button>
				</a-col>
			</a-row>
			<span v-if="isDevelop == 1" style="font-size: 10px;color:#BEBEBE ">*开发表单仅显示主要字段</span>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.processId"
				:scroll="{ x: 1600, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<span slot="actions" slot-scope="text, record">
					<a-space>
						<!-- 修改 -->
						<a class="table-edit" @click="WfInfoShow(record)"> <a-icon type="search" />查看</a>
					</a-space>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import moment from 'moment';
import { abpService } from '@/shared/abp';
import { AppConsts } from '../../../../abpPro/AppConsts';
import { EnterpriseAttendCycleServiceProxy, CustomizeReportServiceServiceProxy } from '@/shared/service-proxies';
import { fileDownloadService } from '@/shared/utils';

export default {
	name: 'dynamicprogress',
	mixins: [AppComponentBase],
	data() {
		return {
			seachFieldText: undefined,
			isFinished: undefined,

			columns: [],
			tableData: [],
			emquit: true,
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			DateTime: [],
			StartTime: null,
			EndTime: null,
			Year: undefined,
			Month: undefined,
			SignCardNumsList: [],
			_fileDownloadService: '',
			headerFieldList: [],
			reportColumns: [],
			wfScheme: [],
			wfId: undefined,
			seachField: undefined,
			seachData: [],
			isDevelop: 1,
		};
	},

	async created() {
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this._CustomizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.Year = moment().year();
		this.Month = moment().month() + 1;
		try {
			//默认第一条
			try {
				this.columns = this.reportColumns;
			} catch (e) {
				this.columns = this.reportColumns;
			}
			this.coumnsInit();
		} catch (e) {
		} finally {
			//this.loading = false;
			//this.getData();
		}
	},

	async mounted() {
		await this.getCycle();
		this.wfScheme = await this.getWfSchemeSelectList();
		if (this.wfScheme != null) {
			if (this.wfScheme.length > 0) {
				this.wfId = this.wfScheme[0].id;
			}
		}
		//this.getData();
	},
	watch: {
		CompanyId: function(value) {
			this.getData();
		},
	},

	methods: {
		refresh() {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.seachField = undefined;
			this.seachData = [];
			this.filterText = undefined;
			this.seachFieldText = undefined;
			//this.getData();
		},
		wfRefresh(option) {
			var wfll = this.wfScheme;
			if (wfll != null) {
				for (var w = 0; w < wfll.length; w++) {
					if (wfll[w].id == option) {
						if (wfll[w].formMes != '开发表单') {
							this.isDevelop = 2;
						} else {
							this.isDevelop = 1;
						}
					}
				}
			}

			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.seachField = undefined;
			this.seachData = [];
			this.filterText = undefined;
			this.seachFieldText = undefined;
			//this.getData();
		},
		getData() {
			this.loading = true;

			this.tableData = [];
			this.reportColumns = [];
			this.seachData = [];
			//字段数据默认添加申请人字段
			this.seachData.push({
				id: 'RealName',
				name: '申请人',
			});

			this._CustomizeReportServiceServiceProxy
				.getCustomReportDynamicList(
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.wfId,
					this.seachField,
					this.isFinished,
					this.seachFieldText,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					//初始化表头

					this.reportColumns.push({
						title: '查看详情',
						dataIndex: 'actions',
						align: 'center',
						fixed: 'left',
						scopedSlots: { customRender: 'actions' },
						width: 100,
					});

					this.reportColumns.push({
						title: '审批状态',
						dataIndex: 'isFinish',
						sorter: false,
						align: 'center',
						width: 100,
					});
					this.reportColumns.push({
						title: '申请人',
						dataIndex: 'createUserName',
						sorter: false,
						align: 'center',
						width: 150,
					});
					this.reportColumns.push({
						title: '申请时间',
						dataIndex: 'creationTime',
						sorter: false,
						align: 'center',
						width: 150,
					});
					//   this.reportColumns.push({
					//     title: "审批过程",
					//     dataIndex: "proStatues",
					//     sorter: false,
					//     align: "center",
					//     width: 150,
					//   });
					this.reportColumns.push({
						title: '审核人',
						dataIndex: 'auditname',
						sorter: false,
						align: 'center',
						width: 150,
					});
					this.reportColumns.push({
						title: '审核结果',
						dataIndex: 'operationname',
						sorter: false,
						align: 'center',
						width: 150,
					});
					if (this.isDevelop == 1) {
						this.reportColumns.push({
							title: '表单详情',
							dataIndex: 'wbfInformation',
							sorter: false,
							align: 'center',
							width: 150,
						});
						this.reportColumns.push({
							title: '申请原因',
							dataIndex: 'wbfRemark',
							sorter: false,
							align: 'center',
							width: 150,
						});
						this.reportColumns.push({
							title: '编号',
							dataIndex: 'wbfFromNo',
							sorter: false,
							align: 'center',
							width: 150,
						});
					}
					if (result.headerFieldList != null) {
						for (var i = 0; i < result.headerFieldList.length; i++) {
							//var field = result.headerFieldList[i].field.charAt(0).toLowerCase()+result.headerFieldList[i].field.charAt(1).toLowerCase() + result.headerFieldList[i].field.slice(2);
							var field = result.headerFieldList[i].field.toLowerCase();
							var objTitle = {
								title: result.headerFieldList[i].title,
								dataIndex: field,
								sorter: false,
								align: 'center',
								width: 150,
								customRender: (text, row, index) => {
									var timeCe = text + '';
									var zm = /[a-z]/i; //是否包含字母
									var hz = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); //是否包含汉字&& !zm.test(text) && !hz.test(text)
									if (timeCe != null && timeCe != undefined && timeCe != '' && timeCe != 'null') {
										//排除当前数据没有值的情况
										if (
											timeCe.indexOf('T') != -1 &&
											timeCe.indexOf('-') != -1 &&
											timeCe.indexOf(':') != -1
										) {
											//只筛选时间格式的数据，目前只能识别指定的特殊字符进行识别
											if (timeCe.indexOf('00:00:00') != -1) {
												text = timeCe ? moment(timeCe).format('YYYY-MM-DD') : ' ';
											} else {
												text = timeCe ? moment(timeCe).format('YYYY-MM-DD  HH:mm') : ' ';
											}
										}

										//字段的特殊数据进行转换：数据字典
									}
									return {
										children: text,
										attrs: {},
									};
								},
							};

							var seaObj = {
								id: field,
								name: result.headerFieldList[i].title,
							};
							this.seachData.push(seaObj);
							this.reportColumns.push(objTitle);
						}

						this.headerFieldList = result.headerFieldList;
					}

					this.columns = this.reportColumns;
					this.tableData = result.reportList;
					this.totalItems = result.totalNum;
					this.tableData.map((item) => {
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD  HH:mm')
							: ' ';
					});
				});
		},

		/**
		 * 导出为excel
		 */
		exportToExcel() {
			this.loading = true;
			this._CustomizeReportServiceServiceProxy
				.getCustomReportDynamicListToExcel(
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.wfId,
					this.seachField,
					this.isFinished,
					this.seachFieldText,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this._fileDownloadService.downloadTempFile(result);
				});
		},

		/**
		 * 获取流程表单列表
		 */
		async getWfSchemeSelectList() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._CustomizeReportServiceServiceProxy
					.getWfSchemeSelectList()
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		WfInfoShow(record) {
			console.log('record:' + record.processId);
			var guid = abpService.abp.utils.getCookieValue('SSOGuid');
			var url =
				AppConsts.LR_Url +
				'/LR_NewWorkFlow/NWFProcess/MonitorDetailsIndexSinglePage' +
				'?abpguid=' +
				guid +
				'&processId=' +
				record.processId;
			// window.open(AppConsts.LR_Url + '?abpguid=' + guid + '&ModuleId=' + 206 + '&processId=' + record.processId);
			window.open(url);
		},

		/**
		 * 获取周期设置
		 */
		async initCycle() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.Year, this.Month)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		async getCycle(e) {
			//let cycle = await this.initCycle();
			//var newst=moment(cycle.startTime).format('YYYY-MM-DD 00:00:00');
			//var newet=moment(cycle.endTime).format('YYYY-MM-DD 23:59:59');
			//this.StartTime = cycle.startTime;
			//this.EndTime = cycle.endTime;
			var now = new Date(); //当前日期
			var nowMonth = now.getMonth(); //当前月
			var nowYear = now.getFullYear(); //当前年
			this.StartTime = moment(new Date(nowYear, nowMonth, 1)).format('YYYY-MM-DD');
			this.EndTime = moment(new Date(nowYear, nowMonth + 1, 0)).format('YYYY-MM-DD');
			this.DateTime = [this.StartTime, this.EndTime];
			return this.DateTime;
		},
		onDateChange(e) {
			if (e.length > 0) {
				this.StartTime = e[0];
				this.EndTime = e[1];
				this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
			}
			this.request.skipCount = 0;
			this.pageNumber = 1;
			//this.getData();
		},
	},
};
</script>

<style scoped></style>
